import Vue from "vue";
import Router from "vue-router";
Vue.use(Router)


let router = new Router({
	// mode: 'history',
	routes: [{
		path: '/',
		name: 'index',
		component: () => import('views/001xinshidaiwenming.vue')
	},
	{
		path: '/01zhihuichengshi',
		name: '智慧城市',
		component: () => import('views/01zhihuichengshi.vue')
	},
	{
		path: '/001xinshidaiwenming',
		name: '新时代文明',
		component: () => import('views/001xinshidaiwenming.vue')
	},
	{
		path: '/003zhihuiwenlv',
		name: '智慧文旅',
		component: () => import('views/003zhihuiwenlv.vue')
	},
	{
		path: '/003zhihuiwenlv copy',
		name: '智慧文旅1',
		component: () => import('views/003zhihuiwenlv copy.vue')
	},
	
	{
		path: '/002zhihuidangjian',
		name: '智慧党建',
		component: () => import('views/002zhihuidangjian.vue')
	},
	{
		path: '/005mojingone',
		name: '魔镜1',
		component: () => import('views/005mojingone.vue')
	},
	{
		path: '/006mojingtwo',
		name: '魔镜2',
		component: () => import('views/006mojingtwo.vue')
	},

	{
		path: '/004welcomYue',
		name: '岳阳欢迎页',
		component: () => import('views/004welcomYue.vue')
	},
	{
		path: '/004welcomSheng',
		name: '报业岳阳欢迎页',
		component: () => import('views/004welcomSheng.vue')
	},
	{
		path: '/007ceremonyVideo',
		name: '开幕式视频1',
		component: () => import('views/007ceremonyVideo.vue')
	},
	{
		path: '/008ceremonyVideo',
		name: '开幕式视频2',
		component: () => import('views/008ceremonyVideo.vue')
	},
	{
		path: '/009ceremonyVideo',
		name: '开幕式视频3',
		component: () => import('views/009ceremonyVideo.vue')
	},
	{
		path: '/010interlude',
		name: '过场1',
		component: () => import('views/010interlude.vue')
	},
	{
		path: '/011interlude',
		name: '过场2',
		component: () => import('views/011interlude.vue')
	},
	{
		path: '/012interlude',
		name: '过场3',
		component: () => import('views/012interlude.vue')
	},
	{
		path: '/013interlude',
		name: '过场4',
		component: () => import('views/013interlude.vue')
	},
	
	{
		path: '/018interIude',
		name: '过场5',
		component: () => import('views/018interIude.vue')
	},
	{
		path: '/014ceremonyVideo',
		name: '开幕式视频4',
		component: () => import('views/014ceremonyVideo.vue')
	},
	{
		path: '/015interlude',
		name: '新时代云平台',
		component: () => import('views/015interlude.vue')
	},
	{
		path: '/016ceremonyVideo',
		name: '开幕式视频5',
		component: () => import('views/016ceremonyVideo.vue')
	},
	{
		path: '/003zhihuiwenlvcByCode',
		name: '智慧文旅by',
		component: () => import('views/003zhihuiwenlvcByCode.vue')
	},
	{
		path: '/017interlude',
		name: '过场5',
		component: () => import('views/017interlude.vue')
	},

	]
})
export default router;
